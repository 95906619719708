<!--
 * @Author: Eric hongyong.yang@nttdata.com
 * @Date: 2023-02-23 17:37:18
 * @LastEditors: Eric hongyong.yang@nttdata.com
 * @LastEditTime: 2023-06-12 16:18:16
 * @FilePath: \edp-web\src\views\entryReport.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <template v-if="detail.templateExample === '1'">
      <!-- pc -->
      <div class="entry-report pc-visible">
        <edp-header
          class="bf"
          :headerLeftWord="$t('member.Anniversaryofentry')"
        ></edp-header>

        <div
          class="entry-content"
          :style="{
            backgroundImage: `url(${bgurl})`,
            backgroundSize: 'cover',
          }"
        >
          <!-- <img src="@/src/assets/member/any-bg1.png" alt="" class="any-bg" /> -->
          <img :src="rightUrl" alt="" class="any-right-pic" />
          <div class="entry-wrap fjsba">
            <div class="left-wrap">
              <p class="mb20 f20">WORK<br />ANNIVERSARY</p>
              <img
                :src="detail.photo || nullImg"
                alt=""
                class="item-pic mb20"
              />
              <P v-if="lang === 'cn'" class="f40 fb"
                ><span class="bgyellow-1 font-jlr txt-nowp">入职</span><br />
                <span class="txt-nowp">周年纪念</span></P
              >
            </div>
            <div class="mid-wrap mt10 tac">
              <img :src="numUrl" alt="" class="num-pic mb32" />
              <p class="f36 fb tac mb16">
                {{ $t("member.anyTitle1") }} {{ detail.thisAnniversary
                }}<span class="f22">{{
                  lang === "en" ? switchRd(detail.thisAnniversary) : ""
                }}</span>
                {{ $t("member.anyTitle2") }}
              </p>
              <span class="f24 bgyellow-1 tac mb24 date">{{
                detail.anniversaryDate
              }}</span>

              <template
                v-if="specialYear.includes(this.detail.thisAnniversary)"
              >
                <P
                  v-if="this.detail.thisAnniversary === 1"
                  class="f24 split-text2"
                  v-html="ToBreak($t(`member.anyDesc1`))"
                ></P>
                <template v-else>
                  <P
                    class="f24 split-text2"
                    v-html="
                      ToBreak($t(`member.anyDesc${detail.thisAnniversary}top`))
                    "
                  ></P>
                  <img
                    src="@/src/assets/member/entry-text.png"
                    alt=""
                    class="entry-pic"
                  />
                  <P
                    class="f24 split-text2"
                    v-html="
                      ToBreak($t(`member.anyDesc${detail.thisAnniversary}bot`))
                    "
                  ></P>
                </template>
              </template>
              <P
                v-else
                class="f24 split-text2"
                v-html="ToBreak($t(`member.anyDesc3`))"
              ></P>
              <p class="right-txt">— THE JLR CHINA FAMILY</p>
            </div>
            <div class="right-wrap">
              <!-- <p class="f24 fb one">一 路 同 行 感 谢 有 你</p> -->
            </div>
            <!-- <img src="@/src/assets/member/bot-dot.png" alt="" class="bottom-pic" /> -->
          </div>
        </div>
      </div>
      <!-- h5 -->
      <div class="entry-report mob-visible">
        <div class="entry-content">
          <!-- <img src="@/src/assets/member/any-bg1.png" alt="" class="any-bg" /> -->
          <!-- <img :src="rightUrl" alt="" class="any-right-pic" /> -->
          <div
            class="entry-wrap"
            :style="{
              backgroundImage: `url(${mbgurl})`,
              backgroundSize: 'cover',
            }"
          >
            <!-- <div class="left-wrap">
            <p class="mb20 f20">WORK<br />ANNIVERSARY</p>
            <img :src="detail.photo || nullImg" alt="" class="item-pic mb20" />
            <P v-if="lang === 'cn'" class="f40 fb"
              ><span class="bgyellow-1 font-jlr txt-nowp">入职</span><br />
              <span class="txt-nowp">周年纪念</span></P
            >
          </div> -->
            <div class="mid-wrap tac">
              <img :src="numUrl" alt="" class="num-pic mb32" />
              <p class="mid-title fb tac cg">
                {{ $t("member.anyTitle1") }} {{ detail.thisAnniversary
                }}<span class="f22">{{
                  lang === "en" ? switchRd(detail.thisAnniversary) : ""
                }}</span>
                {{ $t("member.anyTitle2") }}
              </p>
              <span class="bgyellow-1 tac date">{{
                detail.anniversaryDate
              }}</span>
              <template v-if="specialYear.includes(detail.thisAnniversary)">
                <P
                  v-if="this.detail.thisAnniversary === 1"
                  class="split-text2"
                  v-html="ToBreak($t(`member.anyDesc1`))"
                ></P>
                <template v-else>
                  <P
                    class="split-text2"
                    v-html="
                      ToBreak($t(`member.anyDesc${detail.thisAnniversary}top`))
                    "
                  ></P>
                  <img
                    src="@/src/assets/member/entry-text.png"
                    alt=""
                    class="entry-pic"
                  />
                  <P
                    class="split-text2"
                    v-html="
                      ToBreak($t(`member.anyDesc${detail.thisAnniversary}bot`))
                    "
                  ></P>
                </template>
              </template>
              <P
                v-else
                class="split-text2"
                v-html="ToBreak($t(`member.anyDesc${detail.thisAnniversary}`))"
              ></P>

              <p class="right-txt">— THE JLR CHINA FAMILY</p>
            </div>
            <!-- <div class="right-wrap">
             <p class="f24 fb one">一 路 同 行 感 谢 有 你</p> 
          </div> -->
            <!-- <img src="@/src/assets/member/bot-dot.png" alt="" class="bottom-pic" /> -->
          </div>
        </div>
      </div>
    </template>
    <template v-if="detail.templateExample === '2'">
      <div class="entry-report-two">
        <edp-header
          class="bf"
          :headerLeftWord="$t('member.Anniversaryofentry')"
        ></edp-header>
        <div class="report-content">
          <div class="content-left"></div>
          <div class="content-line"></div>
          <div class="content-right">
            <div class="right-text">
              <p class="f40 cg fb mb32">
                {{ $t("member.anyTitle1") }}
                <span class="f50 cf3"
                  >{{ detail.thisAnniversary
                  }}{{
                    lang === "en" ? switchRd(detail.thisAnniversary) : ""
                  }}</span
                >
                {{ $t("member.anyTitle2") }}
              </p>
              <template
                v-if="specialYear.includes(this.detail.thisAnniversary)"
              >
                <P
                  v-if="this.detail.thisAnniversary === 1"
                  class="f24 mb50 split-text2 nomal"
                  v-html="ToBreak($t(`member.anyDesc1`))"
                ></P>
                <template v-else>
                  <P
                    class="f20 split-text2 special"
                    v-html="
                      ToBreak($t(`member.anyDesc${detail.thisAnniversary}top`))
                    "
                  ></P>
                  <img
                    src="@/src/assets/member/entry-text.png"
                    alt=""
                    class="entry-pic"
                  />
                  <P
                    class="f20 split-text2 special"
                    v-html="
                      ToBreak($t(`member.anyDesc${detail.thisAnniversary}bot`))
                    "
                  ></P>
                </template>
              </template>
              <P
                v-else
                class="f22 mb50 split-text2 nomal"
                v-html="ToBreak($t(`member.anyDesc3`))"
              ></P>
              <p class="right-txt">— THE JLR CHINA FAMILY</p>
            </div>
          </div>
          <img src="@/src/assets/member/star-g01.png" alt="" class="star-one" />
          <img src="@/src/assets/member/star-g02.png" alt="" class="star-two" />
        </div>
        <div class="report-card">
          <div class="card-text tac">
            <img :src="detail.photo || nullImg" alt="" class="item-pic mb10" />
            <p class="f22 tac mb8">
              {{ utils.formatLang(lang, detail.cname, detail.ename) }}
            </p>
            <span class="f22 bgyellow-1 tac mb8 date">{{
              detail.anniversaryDate
            }}</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      id: "",
      bgurl: "",
      numUrl: "",
      rightUrl: "",
      detail: {},
      nullImg: require("@/src/assets/common/default.png"),
      mbgurl: "",
      mnumUrl: "",
      specialYear: [1, 5, 10, 15],
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.login.lang,
    }),
  },
  mounted() {
    console.log(this.$route.query.onlyId);
    this.id = this.$route.query.onlyId;
    this.getDetail();
  },
  methods: {
    ...mapActions({
      anyDetail: "emp/anyDetail",
    }),
    async getDetail() {
      let res = await this.anyDetail(this.id);
      console.log(res);
      this.detail = res.data;
      //this.detail.thisAnniversary = 5;
      this.numUrl = require(`@/src/assets/member/any-num${this.detail.thisAnniversary}.png`);

      if (this.specialYear.includes(this.detail.thisAnniversary)) {
        this.bgurl = require(`@/src/assets/member/any-bg${this.detail.thisAnniversary}.png`);
        this.rightUrl = require(`@/src/assets/member/any-mask${this.detail.thisAnniversary}.png`);
        this.mbgurl = require(`@/src/assets/member/any-mbg${this.detail.thisAnniversary}.png`);
      } else {
        this.bgurl = require(`@/src/assets/member/any-bg3.png`);
        this.rightUrl = require(`@/src/assets/member/any-mask3.png`);
        this.mbgurl = require(`@/src/assets/member/any-mbg3.png`);
      }
    },
    ToBreak(val) {
      return val.replaceAll("|", "<br/>");
    },
    switchRd(val) {
      if (val == 1 || val == 21) return "ST";
      if (val == 2 || val == 22) return "ND";
      if (val == 3 || val == 23) return "RD";
      if (3 < val <= 20 || 23 < val <= 25) return "TH";
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";
@media screen and (min-width: 1366px) {
  .pc-visible {
    display: block;
  }
  .mob-visible {
    display: none;
  }
  .entry-report {
    position: relative;
    width: 100%;
    height: 100%;
    //background: url("../assets/member/reph-bg.png") no-repeat;
    //background-size: cover;

    .entry-content {
      position: relative;
      width: 100%;
      min-height: calc(100% - 80px);

      .any-right-pic {
        position: absolute;
        right: 0;
        top: 0;
        width: toPad(808);
        height: toPad(554);
        z-index: 3;
      }

      .entry-wrap {
        position: relative;
        z-index: 4;
        //min-height: calc(100% - 60px);
        padding: toPad(70) toPad(60) toPad(40);
        .left-wrap {
          position: relative;
          width: toPad(70);
          height: 100%;
          .item-pic {
            width: toPad(100);
            height: toPad(100);
            border-radius: toPad(50);
          }
        }
        .mid-wrap {
          width: toPad(1200);
          padding-bottom: toPad(80);
          .num-pic {
            width: auto;
            height: toPad(272);
          }
          .date {
            display: inline-block;
          }
          .split-text2 {
            line-height: 1.5;
          }
          .entry-pic {
            width: toPad(128);
            height: toPad(13);
            margin: 0 auto;
          }
          .right-txt {
            position: absolute;
            bottom: toPad(60);
            width: 80%;
            text-align: right;
            letter-spacing: 0.2em;
            z-index: 5;
          }
        }
        .right-wrap {
          position: relative;
          width: toPad(70);
          .one {
            width: toPad(24);
            color: #303b95;
          }
        }
        .bottom-pic {
          position: absolute;
          left: toPad(64);
          bottom: toPad(40);
          width: toPad(43);
          height: toPad(138);
        }
      }
    }
  }
  .entry-report-two {
    position: relative;
    width: 100%;
    height: 100vh;
    background: linear-gradient(
        358.88deg,
        rgba(97, 191, 180, 0.7) 14.77%,
        rgba(255, 236, 139, 0.7) 115.14%
      ),
      linear-gradient(0deg, #f4f0ed, #f4f0ed),
      linear-gradient(0deg, rgba(245, 246, 248, 0.5), rgba(245, 246, 248, 0.5)),
      #f9fdfe;
    .report-content {
      position: relative;
      display: flex;
      width: 94%;
      height: 85vh;
      margin: 5vh 3%;
      padding: 3vh 2%;
      background: #ffffff;
      border-radius: 12px;
      &::before {
        position: absolute;
        left: 59%;
        top: toPad(-13);
        content: "";
        width: toPad(26);
        height: toPad(26);
        border-radius: 50%;
        background: #e6e6b3;
      }
      &::after {
        position: absolute;
        left: 59%;
        bottom: -13px;
        content: "";
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background: #9dccc6;
      }
      .content-left {
        width: 58%;
        height: 100%;
        background: url("~@/src/assets/member/entry-left.png") no-repeat;
        background-position: 0 center;
        background-size: cover;
      }
      .content-line {
        position: absolute;
        left: calc(59% + 13px);
        top: 5vh;
        width: 0px;
        height: 74vh;
        border: 1px dashed #8c8c8c;
      }
      .content-right {
        position: relative;
        width: 36%;
        margin: 2vh 0 0 toPad(60);
        .right-text {
          padding-top: 2vh;
          .split-text2 {
            line-height: 1.3;
            &.nomal {
              margin-top: 10vh;
            }
            &.special {
              margin: 4vh 0;
            }
          }
          .entry-pic {
            display: block;
            width: toPad(128);
            height: toPad(13);
            margin: toPad(20) auto;
          }
          .right-txt {
            position: absolute;
            bottom: toPad(60);
            width: 100%;
            text-align: right;
            letter-spacing: 0.2em;
            z-index: 5;
          }
        }
      }
      .star-one {
        position: absolute;
        left: toPad(3);
        bottom: toPad(97);
        width: toPad(48);
        height: toPad(84);
      }
      .star-two {
        position: absolute;
        left: 55.6%;
        top: 55%;
        width: toPad(53);
        height: toPad(93);
      }
    }
    .report-card {
      position: absolute;
      left: 0;
      top: toPad(72);
      width: toPad(476);
      height: toPad(603);
      background: url("~@/src/assets/member/card-bg.png") no-repeat;
      background-size: 100% 100%;
      .card-text {
        width: toPad(246);
        margin: toPad(220) toPad(152) 0;
        transform: rotate(-9.52deg);
        .item-pic {
          display: block;
          width: toPad(246);
          height: toPad(246);
          border-radius: 14px;
        }
      }
    }
  }
}
@media screen and (min-width: 761px) and (max-width: 1366px) {
  .pc-visible {
    display: block;
  }
  .mob-visible {
    display: none;
  }
  .entry-report {
    position: relative;
    width: 100%;
    height: 100%;
    //background: url("../assets/member/reph-bg.png") no-repeat;
    //background-size: cover;

    .entry-content {
      position: relative;
      width: 100%;
      min-height: calc(100% - 80px);

      .any-right-pic {
        position: absolute;
        right: 0;
        top: 0;
        width: toPad(808);
        height: toPad(554);
        z-index: 3;
      }

      .entry-wrap {
        position: relative;
        z-index: 4;
        //min-height: calc(100% - 60px);
        padding: toPad(70) toPad(60) toPad(40);
        .left-wrap {
          position: relative;
          width: toPad(70);
          height: 100%;
          .item-pic {
            width: toPad(100);
            height: toPad(100);
            border-radius: toPad(50);
          }
        }
        .mid-wrap {
          width: toPad(1200);
          padding-bottom: toPad(80);
          .num-pic {
            width: auto;
            height: toPad(272);
          }
          .date {
            display: inline-block;
          }
          .split-text2 {
            line-height: 1.5;
          }
          .entry-pic {
            width: toPad(128);
            height: toPad(13);
            margin: 0 auto;
          }
          .right-txt {
            position: absolute;
            bottom: toPad(60);
            width: 80%;
            text-align: right;
            letter-spacing: 0.2em;
            z-index: 5;
          }
        }
        .right-wrap {
          position: relative;
          width: toPad(70);
          .one {
            width: toPad(24);
            color: #303b95;
          }
        }
        .bottom-pic {
          position: absolute;
          left: toPad(64);
          bottom: toPad(40);
          width: toPad(43);
          height: toPad(138);
        }
      }
    }
  }
  .entry-report-two {
    position: relative;
    width: 100%;
    height: 100vh;
    background: linear-gradient(
        358.88deg,
        rgba(97, 191, 180, 0.7) 14.77%,
        rgba(255, 236, 139, 0.7) 115.14%
      ),
      linear-gradient(0deg, #f4f0ed, #f4f0ed),
      linear-gradient(0deg, rgba(245, 246, 248, 0.5), rgba(245, 246, 248, 0.5)),
      #f9fdfe;
    .report-content {
      position: relative;
      display: flex;
      width: 94%;
      height: 85vh;
      margin: 5vh 3%;
      padding: 3vh 2%;
      background: #ffffff;
      border-radius: 12px;
      &::before {
        position: absolute;
        left: 59%;
        top: toPad(-13);
        content: "";
        width: toPad(26);
        height: toPad(26);
        border-radius: 50%;
        background: #e6e6b3;
      }
      &::after {
        position: absolute;
        left: 59%;
        bottom: -13px;
        content: "";
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background: #9dccc6;
      }
      .content-left {
        width: 58%;
        height: 100%;
        background: url("~@/src/assets/member/entry-left.png") no-repeat;
        background-position: 0 center;
        background-size: cover;
      }
      .content-line {
        position: absolute;
        left: calc(59% + 13px);
        top: 5vh;
        width: 0px;
        height: 74vh;
        border: 1px dashed #8c8c8c;
      }
      .content-right {
        position: relative;
        width: 36%;
        margin: 2vh 0 0 toPad(60);
        .right-text {
          padding-top: 1vh;
          .split-text2 {
            line-height: 1.3;
          }
          .entry-pic {
            display: block;
            width: toPad(128);
            height: toPad(13);
            margin: toPad(20) auto;
          }
          .right-txt {
            position: absolute;
            bottom: toPad(5);
            width: 100%;
            text-align: right;
            letter-spacing: 0.2em;
            z-index: 5;
          }
        }
      }
      .star-one {
        position: absolute;
        left: toPad(3);
        bottom: toPad(97);
        width: toPad(48);
        height: toPad(84);
      }
      .star-two {
        position: absolute;
        left: 55.6%;
        top: 55%;
        width: toPad(53);
        height: toPad(93);
      }
    }
    .report-card {
      position: absolute;
      left: 0;
      top: toPad(72);
      width: toPad(476);
      height: toPad(603);
      background: url("~@/src/assets/member/card-bg.png") no-repeat;
      background-size: 100% 100%;
      .card-text {
        width: toPad(246);
        margin: toPad(220) toPad(152) 0;
        transform: rotate(-9.52deg);
        .item-pic {
          display: block;
          width: toPad(246);
          height: toPad(246);
          border-radius: 14px;
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .pc-visible {
    display: none;
  }
  .mob-visible {
    display: block;
  }
  .entry-report {
    width: 100%;
    height: 100%;
    //background: url("../assets/member/reph-bg.png") no-repeat;
    //background-size: cover;
    .entry-content {
      position: relative;
      width: 100%;
      min-height: 100%;
      background-color: transparent;
      .entry-wrap {
        width: 100%;
        height: 100vh;
        .left-wrap {
          position: relative;
          width: toRem(0);
          .item-pic {
            width: toRem(50);
            height: toRem(50);
            border-radius: toRem(50);
          }
        }
        .mid-wrap {
          width: 100%;
          padding: toRem(60) toRem(28) toRem(20);
          text-align: center;
          .num-pic {
            width: auto;
            height: toRem(236);
          }
          .mid-title {
            width: 60%;
            margin: 0 auto toRem(16);
            font-size: toRem(36);
          }
          .date {
            display: inline-block;
            margin-bottom: toRem(30);
            font-size: toRem(24);
          }
          .split-text {
            letter-spacing: 0.3em;
          }
          .split-text2 {
            width: 90%;
            margin: 0 auto;
            letter-spacing: 0.1em;
            line-height: 1.6;
            font-size: toRem(16);
          }
        }
        .entry-pic {
          width: toRem(112);
          height: toRem(9);
          margin: 0 auto;
        }
        .right-txt {
          margin-top: toRem(80);
          font-size: toRem(12);
        }
      }
      .right-wrap {
        position: relative;
        width: toRem(0);
        .one {
          width: toRem(24);
          color: #303b95;
        }
      }
      .bottom-pic {
        position: absolute;
        left: toRem(4);
        bottom: toRem(4);
        width: toRem(43);
        height: toRem(138);
      }
    }
  }
}
</style>
