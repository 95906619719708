var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.detail.templateExample === '1')?[_c('div',{staticClass:"entry-report pc-visible"},[_c('edp-header',{staticClass:"bf",attrs:{"headerLeftWord":_vm.$t('member.Anniversaryofentry')}}),_c('div',{staticClass:"entry-content",style:({
          backgroundImage: `url(${_vm.bgurl})`,
          backgroundSize: 'cover',
        })},[_c('img',{staticClass:"any-right-pic",attrs:{"src":_vm.rightUrl,"alt":""}}),_c('div',{staticClass:"entry-wrap fjsba"},[_c('div',{staticClass:"left-wrap"},[_vm._m(0),_c('img',{staticClass:"item-pic mb20",attrs:{"src":_vm.detail.photo || _vm.nullImg,"alt":""}}),(_vm.lang === 'cn')?_c('P',{staticClass:"f40 fb"},[_c('span',{staticClass:"bgyellow-1 font-jlr txt-nowp"},[_vm._v("入职")]),_c('br'),_c('span',{staticClass:"txt-nowp"},[_vm._v("周年纪念")])]):_vm._e()],1),_c('div',{staticClass:"mid-wrap mt10 tac"},[_c('img',{staticClass:"num-pic mb32",attrs:{"src":_vm.numUrl,"alt":""}}),_c('p',{staticClass:"f36 fb tac mb16"},[_vm._v(" "+_vm._s(_vm.$t("member.anyTitle1"))+" "+_vm._s(_vm.detail.thisAnniversary)),_c('span',{staticClass:"f22"},[_vm._v(_vm._s(_vm.lang === "en" ? _vm.switchRd(_vm.detail.thisAnniversary) : ""))]),_vm._v(" "+_vm._s(_vm.$t("member.anyTitle2"))+" ")]),_c('span',{staticClass:"f24 bgyellow-1 tac mb24 date"},[_vm._v(_vm._s(_vm.detail.anniversaryDate))]),(_vm.specialYear.includes(this.detail.thisAnniversary))?[(this.detail.thisAnniversary === 1)?_c('P',{staticClass:"f24 split-text2",domProps:{"innerHTML":_vm._s(_vm.ToBreak(_vm.$t(`member.anyDesc1`)))}}):[_c('P',{staticClass:"f24 split-text2",domProps:{"innerHTML":_vm._s(
                    _vm.ToBreak(_vm.$t(`member.anyDesc${_vm.detail.thisAnniversary}top`))
                  )}}),_c('img',{staticClass:"entry-pic",attrs:{"src":require("@/src/assets/member/entry-text.png"),"alt":""}}),_c('P',{staticClass:"f24 split-text2",domProps:{"innerHTML":_vm._s(
                    _vm.ToBreak(_vm.$t(`member.anyDesc${_vm.detail.thisAnniversary}bot`))
                  )}})]]:_c('P',{staticClass:"f24 split-text2",domProps:{"innerHTML":_vm._s(_vm.ToBreak(_vm.$t(`member.anyDesc3`)))}}),_c('p',{staticClass:"right-txt"},[_vm._v("— THE JLR CHINA FAMILY")])],2),_c('div',{staticClass:"right-wrap"})])])],1),_c('div',{staticClass:"entry-report mob-visible"},[_c('div',{staticClass:"entry-content"},[_c('div',{staticClass:"entry-wrap",style:({
            backgroundImage: `url(${_vm.mbgurl})`,
            backgroundSize: 'cover',
          })},[_c('div',{staticClass:"mid-wrap tac"},[_c('img',{staticClass:"num-pic mb32",attrs:{"src":_vm.numUrl,"alt":""}}),_c('p',{staticClass:"mid-title fb tac cg"},[_vm._v(" "+_vm._s(_vm.$t("member.anyTitle1"))+" "+_vm._s(_vm.detail.thisAnniversary)),_c('span',{staticClass:"f22"},[_vm._v(_vm._s(_vm.lang === "en" ? _vm.switchRd(_vm.detail.thisAnniversary) : ""))]),_vm._v(" "+_vm._s(_vm.$t("member.anyTitle2"))+" ")]),_c('span',{staticClass:"bgyellow-1 tac date"},[_vm._v(_vm._s(_vm.detail.anniversaryDate))]),(_vm.specialYear.includes(_vm.detail.thisAnniversary))?[(this.detail.thisAnniversary === 1)?_c('P',{staticClass:"split-text2",domProps:{"innerHTML":_vm._s(_vm.ToBreak(_vm.$t(`member.anyDesc1`)))}}):[_c('P',{staticClass:"split-text2",domProps:{"innerHTML":_vm._s(
                    _vm.ToBreak(_vm.$t(`member.anyDesc${_vm.detail.thisAnniversary}top`))
                  )}}),_c('img',{staticClass:"entry-pic",attrs:{"src":require("@/src/assets/member/entry-text.png"),"alt":""}}),_c('P',{staticClass:"split-text2",domProps:{"innerHTML":_vm._s(
                    _vm.ToBreak(_vm.$t(`member.anyDesc${_vm.detail.thisAnniversary}bot`))
                  )}})]]:_c('P',{staticClass:"split-text2",domProps:{"innerHTML":_vm._s(_vm.ToBreak(_vm.$t(`member.anyDesc${_vm.detail.thisAnniversary}`)))}}),_c('p',{staticClass:"right-txt"},[_vm._v("— THE JLR CHINA FAMILY")])],2)])])])]:_vm._e(),(_vm.detail.templateExample === '2')?[_c('div',{staticClass:"entry-report-two"},[_c('edp-header',{staticClass:"bf",attrs:{"headerLeftWord":_vm.$t('member.Anniversaryofentry')}}),_c('div',{staticClass:"report-content"},[_c('div',{staticClass:"content-left"}),_c('div',{staticClass:"content-line"}),_c('div',{staticClass:"content-right"},[_c('div',{staticClass:"right-text"},[_c('p',{staticClass:"f40 cg fb mb32"},[_vm._v(" "+_vm._s(_vm.$t("member.anyTitle1"))+" "),_c('span',{staticClass:"f50 cf3"},[_vm._v(_vm._s(_vm.detail.thisAnniversary)+_vm._s(_vm.lang === "en" ? _vm.switchRd(_vm.detail.thisAnniversary) : ""))]),_vm._v(" "+_vm._s(_vm.$t("member.anyTitle2"))+" ")]),(_vm.specialYear.includes(this.detail.thisAnniversary))?[(this.detail.thisAnniversary === 1)?_c('P',{staticClass:"f24 mb50 split-text2 nomal",domProps:{"innerHTML":_vm._s(_vm.ToBreak(_vm.$t(`member.anyDesc1`)))}}):[_c('P',{staticClass:"f20 split-text2 special",domProps:{"innerHTML":_vm._s(
                    _vm.ToBreak(_vm.$t(`member.anyDesc${_vm.detail.thisAnniversary}top`))
                  )}}),_c('img',{staticClass:"entry-pic",attrs:{"src":require("@/src/assets/member/entry-text.png"),"alt":""}}),_c('P',{staticClass:"f20 split-text2 special",domProps:{"innerHTML":_vm._s(
                    _vm.ToBreak(_vm.$t(`member.anyDesc${_vm.detail.thisAnniversary}bot`))
                  )}})]]:_c('P',{staticClass:"f22 mb50 split-text2 nomal",domProps:{"innerHTML":_vm._s(_vm.ToBreak(_vm.$t(`member.anyDesc3`)))}}),_c('p',{staticClass:"right-txt"},[_vm._v("— THE JLR CHINA FAMILY")])],2)]),_c('img',{staticClass:"star-one",attrs:{"src":require("@/src/assets/member/star-g01.png"),"alt":""}}),_c('img',{staticClass:"star-two",attrs:{"src":require("@/src/assets/member/star-g02.png"),"alt":""}})]),_c('div',{staticClass:"report-card"},[_c('div',{staticClass:"card-text tac"},[_c('img',{staticClass:"item-pic mb10",attrs:{"src":_vm.detail.photo || _vm.nullImg,"alt":""}}),_c('p',{staticClass:"f22 tac mb8"},[_vm._v(" "+_vm._s(_vm.utils.formatLang(_vm.lang, _vm.detail.cname, _vm.detail.ename))+" ")]),_c('span',{staticClass:"f22 bgyellow-1 tac mb8 date"},[_vm._v(_vm._s(_vm.detail.anniversaryDate))])])])],1)]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mb20 f20"},[_vm._v("WORK"),_c('br'),_vm._v("ANNIVERSARY")])
}]

export { render, staticRenderFns }